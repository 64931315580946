import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import AdConfig from '../../../../constants/list/AdConfig';
import { LIST_UBT_KEY_TYPES, UbtHandler } from '../../../../ubt.v2/list';

const parsePrevCond = prevCond => {
  let flightSegments = prevCond.get('flightSegments'),
    dDate = flightSegments.getIn([0, 'departureDate']),
    dCountryCode = flightSegments.getIn([0, 'departureCountryCode']),
    aCountryCode = flightSegments.getIn([0, 'arrivalCountryCode']),
    aCityCode = flightSegments.getIn([0, 'arrivalCityCode']),
    dCityCode = flightSegments.getIn([0, 'departureCityCode']),
    flightWay = prevCond.getIn(['flightWay']),
    aDate = '';

  if (flightWay == 'D' && flightSegments.get(1)) {
    aDate = flightSegments.getIn([1, 'departureDate']);
  }
  return {
    dCityCode,
    aCityCode,
    dDate,
    aDate,
    flightWay,
    dCountryCode,
    aCountryCode,
  };
};
const getMomentDate = date => {
  return moment(date).isValid() ? moment(date) : null;
};
const isBetweenFromTo = ({ date, rule }) => {
  if (date && rule) {
    let { from, to } = rule,
      isAfterFrom = true,
      isBeforeTo = true;
    date = getMomentDate(date);
    from = getMomentDate(from);
    to = getMomentDate(to);
    if (from) {
      isAfterFrom = date.isSameOrAfter(from);
    }
    if (to) {
      isBeforeTo = date.isSameOrBefore(to);
    }
    return isAfterFrom && isBeforeTo;
  }
  return true;
};
const isIncludeIn = ({ value, rule }) => {
  if (rule && rule.length && value) {
    return rule.indexOf(value) >= 0;
  }
  return true;
};
const validateRule = ({
  dCityCode,
  aCityCode,
  dDate,
  aDate,
  dCountryCode,
  aCountryCode,
  rule,
}) => {
  return (
    isBetweenFromTo({ date: dDate, rule: rule.dDate }) &&
    isBetweenFromTo({ date: aDate, rule: rule.aDate }) &&
    isIncludeIn({ value: dCityCode, rule: rule.dCityCode }) &&
    isIncludeIn({ value: aCityCode, rule: rule.aCityCode }) &&
    isIncludeIn({ value: dCountryCode, rule: rule.dCountryCode }) &&
    isIncludeIn({ value: aCountryCode, rule: rule.aCountryCode })
  );
};
const filterAd = ({ adConfigKey, prevCond, visibleFlightsSize, position }) => {
  let {
      dCityCode,
      aCityCode,
      dDate,
      aDate,
      dCountryCode,
      aCountryCode,
    } = parsePrevCond(prevCond),
    adConfig = AdConfig[adConfigKey] || [];
  let ads = adConfig.filter(config => {
    let rule = config.rule;
    if (visibleFlightsSize) {
      // 需要位置验证
      if (
        rule &&
        visibleFlightsSize > config.position &&
        config.position == position + 1
      ) {
        return validateRule({
          dCityCode,
          aCityCode,
          dDate,
          aDate,
          dCountryCode,
          aCountryCode,
          rule,
        });
      }
    } else {
      // 无需位置验证
      return validateRule({
        dCityCode,
        aCityCode,
        dDate,
        aDate,
        dCountryCode,
        aCountryCode,
        rule,
      });
    }

    return false;
  });
  if (ads && ads.length > 1) {
    ads.sort((ad1, ad2) => ad2.index - ad1.index);
  }
  return ads && ads[0];
};
const AdBar = ({
  adConfigKey,
  prevCond,
  visibleFlightsSize,
  classNames,
  position,
}) => {
  const onAdClick = useCallback((ad, adConfigKey, url) => {
      UbtHandler.onUploadData('c_ad_bar_click', { type: adConfigKey, ...ad });
      window.open(url);
    }, []),
    [ad, setAd] = useState(null),
    [showAdBar, setShowAdBar] = useState(true), // 只有开关打开才会加载并渲染该组件，所以此处可以默认显示广告
    closeAd = useCallback(
      e => {
        e.stopPropagation();
        setShowAdBar(false);
      },
      [showAdBar],
    );
  useEffect(() => {
    if (prevCond && showAdBar) {
      const ad = filterAd({
        adConfigKey,
        prevCond,
        visibleFlightsSize,
        position,
      });
      setAd(ad);
    }
  }, [adConfigKey, prevCond, visibleFlightsSize, position]);
  useEffect(() => {
    if (ad) {
      LIST_UBT_KEY_TYPES.AD_BAR_SHOW.value.process({
        type: adConfigKey,
        ad: ad,
      });
    }
  }, [ad, adConfigKey]);

  if (ad) {
    let { pic, url } = ad;
    return showAdBar ? (
      <a
        onClick={() => {
          onAdClick(ad, adConfigKey, url);
        }}
        className={`pic-recommend ${classNames || ''}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src={pic} alt=""/>
        <span className="close-btn" onClick={e => closeAd(e)}></span>
      </a>
    ) : null;
  }

  return null;
};

export default AdBar;
