import mergeConfig from '../common/mergeConfig'
import Q_CONFIG_KEYS from './qConfigKeys'

const AdConfig = {
    MIDDLE: [{
        "url": "https://pic.c-ctrip.com/fltcommon/complete/ad/card-ad-def.png",
        "pic": "https://pic.c-ctrip.com/fltcommon/complete/ad/card-ad-def.png",
        "position": 1,
        "index": 12,
        "rule": {
            "dCityCode": [
                "BJS",
                "SHA"
            ],
            "aCityCode": [
                "SEL"
            ],
            "dDate": {
                "from": "2020-05-06",
                "to": "2020-08-08"
            }
        }
    }],
    BOTTOM: [{
        "url": "https://pic.c-ctrip.com/fltcommon/complete/ad/card-ad-def.png",
        "pic": "https://pic.c-ctrip.com/fltcommon/complete/ad/card-ad-def.png",
        "index": 12,
        "rule": {
            "dCityCode": [
                "BJS",
                "SHA"
            ],
            "aCityCode": [
                "SEL"
            ],
            "dDate": {
                "from": "2020-05-06",
                "to": "2020-08-08"
            }
        }
    }],
    ASIDE: [{
        "url": "https://pic.c-ctrip.com/fltcommon/complete/ad/card-ad-def.png",
        "pic": "https://pic.c-ctrip.com/fltcommon/complete/ad/card-ad-def.png",
        "index": 12,
        "rule": {
            "dCityCode": [
                "BJS",
                "SHA"
            ],
            "aCityCode": [
                "SEL"
            ],
            "dDate": {
                "from": "2020-05-06",
                "to": "2020-08-08"
            }
        }
    }]
}


export default mergeConfig(Q_CONFIG_KEYS.AD_CONFIG, AdConfig)
